import React from 'react';
import Footer from '@/components/Footer';
import { useLocation } from '@reach/router';
import SEO from '@/components/seo';
import Header from '@/components/Header';
import PrivacyPolicyPage from '@/components/PrivacyPolicy';
import Layout from '@/components/layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" canonical={`${useLocation().host}`} />
      <Header />
      <PrivacyPolicyPage />
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
